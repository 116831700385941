.recent-outings-container {
    padding: 20px;
    overflow-x: hidden;
  }
  
  .scrollable-outings {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 10px;
  }
  
  .scrollable-outings::-webkit-scrollbar {
    height: 8px;
  }
  
  .scrollable-outings::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }
  